import React from 'react';
import './style.scss';
import Email from './email';

const Header = ({ siteTitle }) => (
  <section className="hero gradientBg is-medium-with-navbar">
    <div className="hero-body">
      <div className="container center">
        <article className="media">
          <div className="media-content">
            <div className="content column is-three-quarters">
              <h1 className="is-size-1">Sean Ryan</h1>
              <p className="subtitle is-size-3">
                Hi! I'm a full stack developer who has spent the previous 5
                years at two startup digital agencies as a Senior Project
                Manager. Working with multiple Fortune 500 brands/clients in
                rapidly growing environments has taught me a lot, and now I'm
                now looking to bring my management and programming skills to
                provide new solutions to partners in a full stack engineer role.
              </p>
              <Email />
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
);

export default Header;
