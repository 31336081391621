import React from 'react';
import { Link } from 'gatsby';
import './style.scss';
import resume from '../images/SeanRyan_Resume.pdf';

const Midsection = () => (
  <div>
    <section className="section">
      <div className="container" id="projects">
        <h1 className="title is-size-1">Projects and Resume</h1>
        <div className="columns is-multiline">
          <div className="column is-half">
            <article className="media">
              <div className="media-content">
                <div className="content">
                  <Link to="/dancedancepose/">
                    <h2 className="title is-size-4">Dance Dance Pose</h2>
                  </Link>
                  <p className="subtitle is-size-5">
                    Inspired by games such as Dance Dance Revolution and Rock
                    band, Dance Dance Pose is a singing and dancing game that
                    leverages Google’s Posenet and open source pitch capturing
                    to build a game where users can gain points by singing on
                    key to a song, and dancing to the beat through the action of
                    tapping bubbles that render on their screen.
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-half">
            <article className="media">
              <div className="media-content">
                <div className="content">
                  <Link to="/spot/">
                    <h2 className="title is-size-4">Spot - A Music chatbot</h2>
                  </Link>
                  <p className="subtitle is-size-5">
                    Built on IBM Watson and the Spotify Web API, Spot is a
                    chatbot that provides a few quick prompts, and then analyzes
                    a users tone through Watson's sentiment analyisis API to
                    provide that user with a song recommendation.
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-one-half">
            <article className="media">
              <div className="media-content">
                <div className="content">
                  <Link to="/tastygravy/">
                    <h2 className="title is-size-4">
                      Tasty Gravy E-Commerce Site
                    </h2>
                  </Link>
                  <p className="subtitle is-size-5">
                    A fully functional e-commerce website that was built from
                    the ground up. Users can visit the site, browse items, add
                    items to their cart, and even checkout via Stripe.
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="column is-half">
            <article className="media">
              <div className="media-content">
                <div className="content">
                  <a href={resume} download="Sean_Ryan_Resume">
                    <h2 className="title is-size-4">Resume</h2>
                  </a>
                  <p className="subtitle is-size-5">
                    For your downloading pleasure
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Midsection;
